import React, { useContext } from 'react'
import { gql, useQuery } from '@apollo/client'
import { If, Then, Else } from 'react-if'
import { AsH3, TitleLG } from '@/atoms/Text'
import { slugs } from '@/constants'
import { analytics } from '@/layout/Analytics/Analytics'
import { EdgeStory } from '@/services/StoriesService'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { EmptyStoryCard } from '@/views/WatchProjectView/CommunityView/EmptyStoryCard'
import { WatchProjectContext } from '@/views/WatchProjectView/WatchProjectContext'
import { StoryCard } from '../StoryCard'
import { prepareCommunityNotes, transformStories, transformThankYous } from '../StoryTransfomations'

export const storyFragment = gql`
  fragment StoryFragment on ThankYouFeed {
    id
    publishDate
    thankYouNote
    totalLikes
    authorLocation
    likedByMe(did: $did) {
      id
    }
    userProfile {
      image
      id
      firstName
      country
    }
    video {
      id
      name
      title
      subtitle
      guid
      slug
      posterCloudinaryPath
      source {
        duration
        url
      }
    }
  }
`

const getStoriesQuery = gql`
  ${storyFragment}
  query getStoriesForProject(
    $project: String
    $numStories: Int
    $filterMinChars: Int
    $sort: StorySort
    $did: String
    $userStoriesInput: UserStoriesInput
  ) {
    stories(projectSlug: $project, first: $numStories, filterMinChars: $filterMinChars, sort: $sort, did: $did) {
      edges {
        cursor
        node {
          ...StoryFragment
        }
      }
    }
    userStories(userStoriesInput: $userStoriesInput) {
      stories {
        user {
          id: uuid
          firstName
          image
        }
        id
        totalLikes
        text
        videoUri
        title
        momentId
        momentTimeCode
        subtitle
        isLikedByUser
        isVip
        createdAt
        publishDate
        authorLocation
        posterCloudinaryPath
        duration
        guid: videoGuid
      }
    }
  }
`

export const StoriesList: React.FC = () => {
  const {
    user: { anonymousId },
  } = analytics.getState()

  const { t } = useTranslate('watch')

  const { projectTheme, project } = useContext(WatchProjectContext)

  const projectSlug =
    projectTheme.slug === slugs.homesteadSeries || projectTheme.slug === slugs.homesteadFamilySurvival
      ? slugs.homestead
      : projectTheme.slug === slugs.riotAndTheDanceSeries
      ? slugs.riot
      : projectTheme.slug
  const { loading, data } = useQuery(getStoriesQuery, {
    variables: {
      project: projectSlug,
      numStories: projectTheme.stories.numStories,
      filterMinChars: projectTheme.stories.filterMinChars,
      sort: projectTheme.stories.sort,
      did: anonymousId,
      userStoriesInput: { where: { projectSlug: projectSlug } },
    },
    errorPolicy: 'all',
  })

  const thankYouNotesArray = data?.stories?.edges?.map((edges: EdgeStory) => edges.node).flat()
  const thankYouNotes = transformThankYous(thankYouNotesArray, projectSlug)
  const momentStories = transformStories(data?.userStories?.stories, projectSlug)

  const notes = prepareCommunityNotes(thankYouNotes, momentStories)

  if (loading) {
    return (
      <div className="animate-pulse">
        <EmptyStoryCard />
        <EmptyStoryCard />
        <EmptyStoryCard />
        <EmptyStoryCard />
        <EmptyStoryCard />
      </div>
    )
  }

  // The default list of stories without a projectSlug is the the-chosen. We don't want to accidentally show Chosen stories on another project
  if (!projectSlug || data?.stories?.edges?.length === 0) {
    return (
      <TitleLG weight="bold" as={AsH3} className="mx-16 pt-8 text-center">
        {t(
          'noOneHasSharedTheirStory',
          'No one has shared their {{projectName}} story yet. Say Thank You the next time you watch an episode!',
          { projectName: project?.name || '' },
        )}
      </TitleLG>
    )
  }

  return (
    <>
      <div className="my-6 flex flex-row flex-wrap items-center">
        <h2 aria-label="community-tab-header" className="mr-auto text-2xl font-semibold">
          <Translate t={t} i18nKey="community">
            Community
          </Translate>
        </h2>
      </div>
      <div className="space-y-2">
        <If condition={loading}>
          <Then>
            <div className="animate-pulse">
              <EmptyStoryCard />
              <EmptyStoryCard />
              <EmptyStoryCard />
              <EmptyStoryCard />
              <EmptyStoryCard />
            </div>
          </Then>
          <Else>
            {notes?.map((story) => {
              return <StoryCard className="min-w-[350px] p-4" key={story.id} abbreviated story={story} />
            })}
          </Else>
        </If>
      </div>
    </>
  )
}
