import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { Tab } from '@headlessui/react'
import router, { useRouter } from 'next/router'
import Sticky from 'react-stickynode'
import { Downloadable } from '@/services/DownloadablesService'
import { Project } from '@/services/ProjectsService'
import { PageDataContext, PageModel } from '@/services/RenderService'
import { Story, ThankYouNote, UserStory } from '@/services/StoriesService'
import { Maybe, ProjectTheme } from '@/types/codegen-contentful'
import { isStoryRoute } from '@/utils/PushToMobileUtil/PushToMobileUtil'
import { useTranslate } from '@/utils/translate/translate-client'
import { WatchAboutView } from '@/views/WatchAboutView'
import { CommunityView } from '@/views/WatchProjectView/CommunityView'
import { DepartmentTab, DepartmentTabHeader, DepartmentTabList } from '@/views/WatchProjectView/DepartmentTabs'
import { WatchTabView } from '@/views/WatchTabView'

export enum WatchDepartment {
  watch,
  about,
  community,
}

export interface WatchDepartmentsProps {
  slug: string
  downloadables: Downloadable[]
  singleStory?: boolean
  story?: Story
  projectData: Project
  loadingProjectData: boolean
  page: PageModel
  pageDataContext: PageDataContext
  contentfulProjectTheme: Maybe<ProjectTheme>
  isGuildMember?: boolean
}

export const WatchDepartments: FC<WatchDepartmentsProps> = ({
  projectData,
  loadingProjectData,
  downloadables,
  slug,
  singleStory,
  story,
  page,
  pageDataContext,
  contentfulProjectTheme,
  isGuildMember = false,
}) => {
  const { asPath } = useRouter()

  const [selectedTab, setSelectedTab] = React.useState(0)

  const tabs = useMemo(() => {
    return contentfulProjectTheme?.watchTabs
  }, [contentfulProjectTheme])

  useEffect(() => {
    const defaultTabIndexFromRoute = getDefaultTabIndexFromRoute(asPath)
    setSelectedTab(defaultTabIndexFromRoute)
  }, [asPath])

  const { t } = useTranslate('watch')

  const onTabChange = useCallback(
    async (index: number) => {
      setSelectedTab(index)
      const cleanPath = asPath.split('#')[0]
      const hashParam = tabs?.[index] || tabs?.[0]
      await router.push(`${cleanPath}#${hashParam}`, undefined, { shallow: true })
    },
    [asPath, tabs],
  )

  const canShowWatchTab = !tabs?.some((value) => value === 'watch')
  const canShowAboutTab = !tabs?.some((value) => value === 'about')
  const canShowCommunityTab = !tabs?.some((value) => value === 'community')

  if (tabs?.length === 0) return null

  return (
    <div className="relative w-full bg-core-gray-950 py-6">
      <Tab.Group selectedIndex={selectedTab} onChange={onTabChange} manual>
        <Sticky activeClass="z-[998] bg-core-gray-950 relative active">
          <DepartmentTabList>
            <DepartmentTabHeader id="watch" hidden={canShowWatchTab}>
              {t('watch', 'Watch')}
            </DepartmentTabHeader>
            <DepartmentTabHeader id="about" hidden={canShowAboutTab}>
              {t('about', 'About')}
            </DepartmentTabHeader>
            <DepartmentTabHeader id="community" hidden={canShowCommunityTab}>
              {t('community', 'Community')}
            </DepartmentTabHeader>
          </DepartmentTabList>
        </Sticky>
        <Tab.Panels id="department-panels" className="focus:border-none focus:outline-none">
          <DepartmentTab>
            <WatchTabView
              projectData={projectData}
              loadingProjectData={loadingProjectData}
              downloadables={downloadables}
              slug={slug}
              page={page}
              pageDataContext={pageDataContext}
              isGuildMember={isGuildMember}
            />
          </DepartmentTab>
          <DepartmentTab>
            <WatchAboutView />
          </DepartmentTab>
          <DepartmentTab>
            <CommunityView singleStory={singleStory} story={story as ThankYouNote | UserStory} />
          </DepartmentTab>
        </Tab.Panels>
      </Tab.Group>
    </div>
  )
}

function getDefaultTabIndexFromRoute(url: string): number {
  if (isStoryRoute(url)) {
    return WatchDepartment.community
  }
  const hash: string = url.split('#')?.[1]

  if (!isWatchDepartment(hash)) {
    return WatchDepartment.watch
  }

  return WatchDepartment[hash]
}

function isWatchDepartment(value: string): value is keyof typeof WatchDepartment {
  return value in WatchDepartment
}

WatchDepartments.displayName = 'WatchDepartments'
