import { createContext, PropsWithChildren, ReactElement, useContext, useMemo, useState } from 'react'
import { GetAppBanner } from '@/organisms/GetAppBanner'
import { AppBannerAnalyticsContext } from '@/organisms/GetAppBanner/types'

export interface GetAppBannerContext extends PropsWithChildren<unknown> {
  deepLink: string
  bannerVisible: boolean
  isLoading: boolean
}

export type GetAppBannerProviderProps = PropsWithChildren<{
  deepLink: string
  projectSlug?: string
  context: AppBannerAnalyticsContext
}>

export type GetAppBannerProvider = (props: GetAppBannerProviderProps) => ReactElement<GetAppBannerContext>

export const GetAppBannerContext = createContext<GetAppBannerContext>({
  deepLink: '',
  bannerVisible: false,
  isLoading: true,
})

interface GetBannerVisibleProps {
  shouldShowAnnouncement: boolean
  variationKey?: string | null
  isMobile?: boolean
}

export const getBannerVisible = ({ shouldShowAnnouncement, isMobile }: GetBannerVisibleProps): boolean => {
  if (shouldShowAnnouncement) return false
  return Boolean(isMobile)
}

export const GetAppBannerProvider = ({
  children,
  deepLink,
  projectSlug,
  context,
}: GetAppBannerProviderProps): ReactElement<GetAppBannerContext> => {
  const [bannerVisible, setBannerVisible] = useState(false)

  const value = useMemo(() => {
    return { bannerVisible, deepLink, isLoading: false }
  }, [bannerVisible, deepLink])

  return (
    <GetAppBannerContext.Provider value={value}>
      {children}
      <GetAppBanner
        deepLink={deepLink}
        showBanner={bannerVisible}
        onClose={() => {
          sessionStorage.setItem('getAppBannerDismissed', 'true')
          setBannerVisible(false)
        }}
        analytics={{
          context,
          projectSlug,
        }}
      />
    </GetAppBannerContext.Provider>
  )
}

export const useGetAppBanner = (): GetAppBannerContext => {
  return useContext(GetAppBannerContext)
}
