import { Else, If, Then } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { NamedColor } from '@/constants/types'
import { PayItForwardConnectedThumbnailCarousel } from '@/molecules/PayItForwardConnectedThumbnailCarousel'
import { HorizontalShopifyMerchScroller } from '@/organisms/HorizontalShopifyMerchScroller'
import { MerchDiscount } from '@/organisms/HorizontalShopifyMerchScroller/HorizontalShopifyMerchScroller'

export interface MerchPifSectionProps {
  hasShopifyConfig?: boolean
  storeUrl?: string
  textColor?: NamedColor
  accessToken?: string
  handle?: string
  storeHref?: string
  slug: string
  pifEnabled?: boolean
  isTop?: boolean
  isDarkMode?: boolean
  headerClassName?: string
  title?: string
  discount?: MerchDiscount
  padding?: boolean
}

export const MerchPifSection: React.FC<MerchPifSectionProps> = ({
  hasShopifyConfig,
  storeUrl,
  accessToken,
  handle,
  storeHref,
  slug,
  pifEnabled,
  isTop,
  textColor,
  isDarkMode = true,
  discount,
  headerClassName,
  title,
  padding = false,
}) => {
  const elevateMerchCarousel = slug === 'tuttle-twins' || slug === 'wingfeather-saga'
  const showShopify = (elevateMerchCarousel && isTop) || (!elevateMerchCarousel && !isTop)

  return (
    <If condition={showShopify}>
      <Then>
        {hasShopifyConfig && (
          <LazyHydrate whenVisible>
            <HorizontalShopifyMerchScroller
              title={title}
              headerClassName={headerClassName}
              isDarkMode={isDarkMode}
              textColor={textColor}
              discount={discount}
              storeUrl={storeUrl as string}
              accessToken={accessToken as string}
              handle={handle as string}
              storeHref={storeHref as string}
              analytics={{
                program: slug,
                context: 'watch-tab',
              }}
              utm={{
                linkContext: 'watch-tab',
                campaign: 'elevated-shopify-carousel',
              }}
              slug={slug}
              padding={padding}
            />
          </LazyHydrate>
        )}
      </Then>
      <Else>
        {pifEnabled && (
          <section id="pay-it-forward" className="scroll-mt-24">
            <LazyHydrate whenVisible>
              <PayItForwardConnectedThumbnailCarousel page="watch" context="watch-tab" padding />
            </LazyHydrate>
          </section>
        )}
      </Else>
    </If>
  )
}
